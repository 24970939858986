import { buttonVariants } from '../ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Anchor } from '../ui/anchor';

// the main demo button
export const DemoButton = () => {
  return (
    <div>
      <div className="pt-2 md:pt-8 flex items-center gap-2 flex-1 w-full">
        <Anchor
          href={'/credits/new'}
          className={`flex-1  font-mono uppercase md:text-xl py-6.5 ${buttonVariants({ variant: 'defaultBrand', size: 'lg' })}`}
        >
          Get Started
        </Anchor>
        <Dialog>
          <DialogTrigger
            className={`flex-1  font-mono uppercase py-6.5 ${buttonVariants({
              variant: 'ghost',
              size: 'lg',
            })}`}
          >
            View Preview
          </DialogTrigger>
          <DialogContent className="min-w-[75vw]">
            <DialogHeader>
              <DialogTitle>Preview Example</DialogTitle>
              <DialogDescription>
                This is a demo video using Spider in a real time web application.
              </DialogDescription>
            </DialogHeader>
            <div className="py-4 overflow-auto">
              <Tabs defaultValue="screenshot" className="w-full">
                <TabsList className="w-full">
                  <TabsTrigger value="screenshot" className="flex-1">
                    Live Screenshots
                  </TabsTrigger>
                  <TabsTrigger value="code" className="flex-1">
                    Live Crawl
                  </TabsTrigger>
                </TabsList>
                <TabsContent value="screenshot" className="w-full">
                  <video
                    controls
                    width="400"
                    height={'400'}
                    aria-label="Screenshot websites in realtime"
                    className="w-full max-h-[50vh]"
                    autoPlay
                  >
                    <source src={`/video/realtime-screenshots.mp4`} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </TabsContent>
                <TabsContent value="code" className="w-full">
                  <video
                    controls
                    width="400"
                    height={'400'}
                    aria-label="Realtime VSCode websites code"
                    className="w-full max-h-[50vh]"
                    autoPlay
                  >
                    <source src={`/video/realtime-code-multi.mp4`} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </TabsContent>
              </Tabs>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};
